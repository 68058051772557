<template>
  <div id="landing0824">
    <section class="thanks">
      <div class="container py-7">
        <i class="isologo isologo-lg"></i>
        <h2>{{ $lang("title") }}</h2>
        <h3>{{ $lang("subtitle") }}</h3>
        <br />
        <h5><b>{{ $lang("text") }}</b></h5>

        <div class="socials">
          <a
            href="https://www.instagram.com/nlcollege.spain/"
            target="_blank"
            class="btn btn-white btn-rounded btn-sm"
          >
            <i class="fab fa-instagram"></i>
          </a>
          <a
            href="https://www.tiktok.com/@nlcollege.spain"
            target="_blank"
            class="btn btn-white btn-rounded btn-sm"
          >
            <i class="fab fa-tiktok"></i>
          </a>
          <a
            href="https://ne-np.facebook.com/nlcollege/"
            target="_blank"
            class="btn btn-white btn-rounded btn-sm"
          >
            <i class="fab fa-facebook-f"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/nlcollege-spain/mycompany/"
            target="_blank"
            class="btn btn-white btn-rounded btn-sm"
          >
            <i class="fab fa-linkedin-in"></i>
          </a>
          <a
            href="https://www.youtube.com/c/NLCollegeSpain/"
            target="_blank"
            class="btn btn-white btn-rounded btn-sm"
          >
            <i class="fab fa-youtube"></i>
          </a>
        </div>

        <div class="d-flex gap-4 align-items-center justify-content-center">
          <a href="http://nlcollege.es" target="_blank"> nlcollege.es </a>
          <a href="mailto:info@nlcollege.es" target="_blank">
            info@nlcollege.es
          </a>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
